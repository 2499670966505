<template>
  <div>
    <b-row v-if="!firstLoader">
      <b-col :sm="12">
        <h3>
          {{ heading | capitalize }}
        </h3>
      </b-col>
    </b-row>
    <template v-if="!checkboxLoader">
      <template v-if="_.size(items) > 0">
        <v-checkbox
          v-show="_.size(items) > 0"
          :id="selectAllItems + '-' + modelName"
          :indeterminate="selectAllItemsIndeterminate"
          v-model="selectAllItems"
        >
          <template v-slot:label>
            <strong>{{ $t("SELECT_ALL") }}</strong>
          </template>
        </v-checkbox>
        <v-divider v-if="!firstLoader"></v-divider>
        <b-form-row v-if="!firstLoader">
          <b-col
            :sm="sm"
            v-for="(item, index) in items"
            v-bind:key="modelName + '-key-' + index"
          >
            <v-checkbox
              style="font-size: 10px"
              :id="modelName + '-' + index"
              v-model="temp"
              :label="item[labelKey]"
              :value="item.id"
            ></v-checkbox>
          </b-col>
        </b-form-row>
      </template>
      <b-alert
        show
        variant="light"
        v-show="_.size(items) == 0 && !checkboxLoader && !firstLoader"
      >
        {{ $t("NO_DATA") }}
      </b-alert>
    </template>
    <template v-else>
      <v-progress-circular indeterminate size="24"></v-progress-circular>
    </template>
  </div>
</template>
<script>
export default {
  name: "CheckboxList",
  inheritAttrs: false,
  props: {
    /**
     */
    heading: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedItems: {
      type: Array,
      default: function () {
        return [];
      }
    },
    modelName: {
      type: String,
      default: "",
      required: true
    },
    labelKey: {
      type: String,
      default: "name",
      required: true
    },
    sm: {
      type: String,
      default: "12",
      required: true
    },
    firstLoader: Boolean,
    checkboxLoader: Boolean
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    temp: {
      get: function () {
        return this.selectedItems;
      },
      set: function (newValue) {
        this.$emit("update:selectedItems", newValue);
      }
    },
    selectAllItemsIndeterminate: {
      get: function () {
        let vm = this;
        return vm.items
          ? vm.selectedItems &&
              vm.selectedItems.length < vm.items.length &&
              vm.selectedItems.length > 0
          : false;
      },
      set: function () {}
    },
    selectAllItems: {
      get: function () {
        let vm = this;
        return vm.items && vm.selectedItems
          ? vm.selectedItems.length == vm.items.length
          : false;
      },
      set: function (value) {
        let vm = this;
        var selected = [];

        if (value) {
          vm.items.forEach(function (item) {
            selected.push(item.id);
          });
        }
        this.$emit("update:selectedItems", selected);
      }
    }
  },
  methods: {}
};
</script>
