<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-suppliers' }">{{
            $t("SUPPLIERS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center"></div>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm>
            <v-text-field
              :value="form.name"
              :label="$t('SUPPLIER')"
              outlined
              disabled
              readonly
            ></v-text-field>
          </b-col>
        </b-row>
        <v-tabs
          v-if="!firstLoader"
          background-color="white"
          color="primary accent-4"
          centered
          icons-and-text
        >
          <v-tab> {{ $t("DESCRIPTION") }}<v-icon>mdi-note-text</v-icon></v-tab>

          <v-tab>
            {{ $t("STORES") }}

            <v-badge
              :value="_.size(form.stores) == 0"
              color="orange"
              content="!"
              overlap
            >
              <v-icon>mdi-home</v-icon>
            </v-badge>
          </v-tab>

          <v-tab-item :transition="false" :reverse-transition="false">
            <b-form>
              <FormBuilder
                v-if="!firstLoader"
                :form="form"
                :schemaJson="schemaJson"
                :v="$v"
              ></FormBuilder>

              <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
              <v-overlay
                :value="
                  !firstLoader && (isLoadingSupplier || isLoadingSharedStore)
                "
              >
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </b-form>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <CheckboxList
                  :heading="$t('STORES')"
                  :items="stores"
                  :selectedItems.sync="form.stores"
                  :modelName="'stores'"
                  :firstLoader="firstLoader"
                  :checkboxLoader="isLoadingSharedProvider"
                  :labelKey="'nameWithCity'"
                  :sm="4"
                >
                </CheckboxList>
                <SkeletonLoaderCheckboxes
                  :visible="firstLoader"
                ></SkeletonLoaderCheckboxes>
              </b-col>
            </b-row>
          </v-tab-item>
        </v-tabs>
        <v-overlay
          :value="!firstLoader && (isLoadingSupplier || isLoadingSharedStore)"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['suppliers-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSupplier"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_SUPPLIER,
  UPDATE_SUPPLIER,
  DELETE_SUPPLIER
} from "@/modules/supplier/store/supplier.module";

import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import { FETCH_STORES_BASIC } from "@/core/services/store/shared_store.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import CheckboxList from "@/view/content/forms/components/CheckboxList.vue";
import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      storesLoader: true,
      currencies: {},
      vatGroups: {},
      supplier: {},
      stores: [],
      isEdit: true
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    CheckboxList
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Suppliers", route: { name: "list-suppliers" } },
      { title: "Edit Supplier" }
    ]);
    vm.getStores();
    vm.getSelects();
    vm.getSupplier();
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingSupplier", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "switch",
            groupId: "item-worksWithDeliveryNote-group",
            id: "item-worksWithDeliveryNote-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "worksWithDeliveryNote-live-feedback",
            colSm: "auto",
            divider: true,
            i18n: {
              label: "WORKS_WITH_DELIVERY_NOTE",
              placeholder: "WORKS_WITH_DELIVERY_NOTE"
            },
            validations: {
              required: true
            },
            model: "worksWithDeliveryNote"
          },
          {
            type: "switch",
            groupId: "item-isForeign-group",
            id: "item-isForeign-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "isForeign-live-feedback",
            colSm: "auto",
            divider: false,
            i18n: {
              label: "IS_FOREIGN",
              placeholder: "IS_FOREIGN"
            },
            validations: {
              required: true
            },
            model: "isForeign"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "stock-supplier-group",
            id: "stock-supplier-input",
            feedback_id: "stock-supplier-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "CURRENCY",
              placeholder: "CURRENCY"
            },
            options: this.currencies,
            validations: {
              required: true
            },
            model: "currencyId"
          },
          {
            type: "select",
            inputType: "select",
            id: "stock-supplier-vat_rate-input",
            groupId: "stock-supplier-vat_rate-group",
            required: "required",
            feedback_id: "stock-supplier-live-feedback",
            divider: false,
            i18n: {
              label: "VAT_RATE",
              placeholder: "VAT_RATE"
            },
            options: this.vatGroups,
            validations: {},
            translatable: false,
            model: "vatGroupId"
          },
          {
            type: "input",
            inputType: "text",
            id: "supplier-name-input",
            groupId: "supplier-name-group",
            required: "required",
            feedback_id: "supplier-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "name"
          },
          {
            type: "input",
            inputType: "text",
            id: "supplier-bulstat-input",
            groupId: "supplier-bulstat-group",
            required: "required",
            feedback_id: "supplier-live-feedback",
            divider: false,
            i18n: {
              label: "BULSTAT",
              placeholder: "BULSTAT",
              validation: "VALID_BULSTAT"
            },
            validations: {},
            translatable: false,
            model: "bulstat"
          },
          {
            type: "input",
            inputType: "text",
            id: "supplier-vat_number-input",
            groupId: "supplier-vat_number-group",
            required: "required",
            feedback_id: "supplier-live-feedback",
            divider: false,
            i18n: {
              label: "VAT_NUMBER",
              placeholder: "VAT_NUMBER",
              validation: "VALID_VAT_NUMBER"
            },
            validations: {},
            translatable: false,
            model: "vatNumber"
          },
          {
            type: "input",
            inputType: "text",
            id: "supplier-owner-input",
            groupId: "supplier-owner-group",
            required: "required",
            feedback_id: "supplier-live-feedback",
            divider: true,
            i18n: {
              label: "OWNER",
              placeholder: "OWNER",
              validation: "VALID_OWNER"
            },
            validations: {},
            translatable: false,
            model: "owner"
          },
          {
            type: "input",
            inputType: "text",
            id: "supplier-address-input",
            groupId: "supplier-address-group",
            required: "required",
            feedback_id: "supplier-live-feedback",
            divider: false,
            i18n: {
              label: "ADDRESS",
              placeholder: "ADDRESS",
              validation: "VALID_ADDRESS"
            },
            validations: {},
            translatable: false,
            model: "address"
          },

          {
            type: "input",
            inputType: "number",
            id: "supplier-number-input",
            groupId: "supplier-number-group",
            required: "required",
            feedback_id: "supplier-live-feedback",
            divider: true,
            i18n: {
              label: "AJUR_NUMBER",
              placeholder: "AJUR_NUMBER",
              validation: "VALID_NUMBER"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: false,
            model: "number"
          },
          {
            type: "input",
            inputType: "text",
            id: "store-echo-name-input",
            groupId: "store-echo-name-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: false,
            i18n: {
              label: "ECHO_NAME",
              placeholder: "ECHO_NAME",
              validation: "ECHO_NAME"
            },
            validations: {},
            translatable: false,
            model: "echoName"
          },
          {
            type: "input",
            inputType: "text",
            id: "store-echo-id-input",
            groupId: "store-echo-id-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: false,
            i18n: {
              label: "ECHO_ID",
              placeholder: "ECHO_ID",
              validation: "ECHO_ID"
            },
            validations: {},
            translatable: false,
            model: "echoId"
          }
        ]
      };
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    const passwordValidations = {
      required: true,
      minLength: 6
    };
    if ("password" in vm.form) {
      tmpValidationObject.form["password"] = vm.setItemValidations(
        passwordValidations
      );
      return tmpValidationObject;
    } else {
      return tmpValidationObject;
    }
  },
  methods: {
    getSelects() {
      let vm = this;
      let payload = {
        currencies: {
          fields: ["id", "name", "code"],
          search: ""
        },
        vat_groups: {
          fields: ["id", "name", "percent"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.currencies = data.data.currencies;
          vm.vatGroups = data.data.vat_groups;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getSupplier() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_SUPPLIER, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.$nextTick(function() {
            vm.supplier = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.supplier).then(
              () => {
                vm.$set(vm.form, "stores", vm.supplier.stores);

                vm.prepareChangesCheck();
                vm.firstLoader = false;
              }
            );
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-suppliers" });
          }
        });
    },
    getStores() {
      let vm = this;
      vm.storesLoader = true;
      this.$store
        .dispatch(FETCH_STORES_BASIC)
        .then(data => {
          vm.stores = data.data;
          // vm.form.stores = vm.supplier.stores;
        })
        .catch(response => {
          console.log(response);
        })
        .finally(() => {
          vm.storesLoader = false;
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_SUPPLIER, { id: vm.supplier.id, payload: this.form })
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          vm.prepareChangesCheck().then(() => {
            // let itemEdit = data.data;
            if (next == "continue") {
              //New
              // this.$router.push({
              //   name: "edit-supplier",
              //   params: { id: itemEdit.id }
              // });
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-supplier" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-suppliers" });
            }
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_SUPPLIER, vm.supplier.id)
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });

              vm.prepareChangesCheck().then(() => {
                vm.$router.push({ name: "list-suppliers" });
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
